const { createContext, useContext, useState } = require("react");

const LoaderContext = createContext();
const LoaderProvider = ({ children }) => {
  const [loader, setLoader] = useState(true);
  return (
    <LoaderContext.Provider value={{ loader, setLoader }}>
      {children}
    </LoaderContext.Provider>
  );
};

const useLoader = () => useContext(LoaderContext);

export { LoaderProvider, useLoader };
